<template>
  <v-main>
    <v-container>
      <v-flex 12>
        <v-radio-group v-model="postCategoryID" mandatory>
          <template v-slot:label>
            <div>Post Category</div>
          </template>
          <v-radio
            v-for="(l, idx) in filterCategory"
            dense
            :key="idx"
            :label="`${l.category_name}`"
            :value="l.id.toString()"
            :active-class="post_category_id == l.id ? 'v-item--active' : ''"
            @change="setPostCategory(l.id)"
          ></v-radio>
        </v-radio-group>
      </v-flex>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: "PostCategory",
  props: {
    post_category_id: {
      type: String,
    },
    language_id: {
      type: String,
    },
  },
  data() {
    return {
      postCategoryID: parseInt(this.post_category_id),
      languageId: parseInt(this.languageId),
      postCategoryList: [],
      renderComponent: true,
    };
  },
  created() {
    this.postCategoryList = JSON.parse(localStorage.getItem("postCategory"));
    if (this.post_category_id != "") {
      this.postCategoryID = this.post_category_id;
      this.setPostCategory(this.post_category_id);
    }
  },
  computed: {
    filterCategory() {
      if (this.language_id) {
        return this.postCategoryList.filter((item) => {
          return item.language_id == this.language_id;
        });
      } else {
        return this.postCategoryList;
      }
    },
  },
  methods: {
    setPostCategory(postCateogryID) {
      localStorage.setItem("selectedCategoryID", postCateogryID);
    },
  },
};
</script>

